<template>
  <div class="columns is-multiline">
    <div
      class="column is-12 has-background-dark"
      :class="{ 'is-rounded-bottom': $mq !== 'mobile' }"
    >
      <div class="columns is-multiline is-mobile is-vcentered spec-check-style">
        <div class="column">
          <p class="has-text-white">
            <span class="icon mr-2">
              <i class="fas fa-boxes has-text-light" />
            </span>
            <span class="spec-check-style">Spec Check</span>
          </p>
        </div>
        <div class="column is-narrow">
          <button
            class="button is-small is-rounded themed-button"
            :class="{ 'is-loading': loading }"
            @click="openModal"
            data-test-id="companion-spec-check-button"
          >
            Request Spec Check
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'CompanionOptionsButton',
  computed: {
    ...mapGetters('companion/options', ['optionsData']),
    ...mapGetters('companion', ['loading']),
    ...mapGetters('auth', ['permissions']),
    hasOptions() {
      const { vehicleOptions, codedVehicleOptions } = this.permissions
      return vehicleOptions || codedVehicleOptions
    }
  },
  methods: {
    track() {
      const type = this.hasOptions ? 'check' : 'upsell'
      this.$mxp.track(`companion_valuation_actions_open_options_${type}_modal`)
    },
    openModal() {
      this.track()
      const modal = this.hasOptions ? '' : 'Upsell'
      this.$modal.open(`companion/CompanionOptions${modal}`)
    }
  }
}
</script>

<style lang="sass" scoped>
.is-rounded-bottom
  border-bottom-left-radius: $radius
  border-bottom-right-radius: $radius
abbr
  border-bottom: 1px dotted currentColor
.spec-check-style
  color: $tab-text-color !important
  background-color: $tab-background-color !important
</style>
